// Formato estrellas (Tab hoteles)
export const splitEstrellas = (textoEstrellas: string | undefined) => {
  const parts = textoEstrellas?.split(' ') ?? [] // dividir el string en un array
  const countEstrellas = parts[0]?.split('*').length - 1 // obtener la cantidad de asteriscos
  const rightString = parts[1] || null // obtener el string de la parte derecha del espacio, si existe
  return { countEstrellas, rightString }
}

// Obtener array precios
export const extractIdAndPrice = (hotels:any[]) => {
  return hotels?.map(hotel => ({
    id: hotel.id,
    price: hotel.price
  }))
}

// Obtener precio desde id
export const getPriceById: any = (id: number, hotels:any[]) => {
  return hotels.find(hotel => hotel.id === id)?.price
}

// Obtener info hoteles
export const extractHotelInfo = (dataHoteles: any) => {
  const tabs = dataHoteles?.data?.destination?.map((item: { name: string }) => ({ ...item, label: item.name }))
  const stars = dataHoteles?.data?.stars
  const lista = dataHoteles?.data?.hotel?.reduce((acc: any[], hotel: { destination: string }) => {
    const count = acc.filter(h => h.destination === hotel.destination)?.length
    if (count < 4) {
      acc.push(hotel)
    }
    return acc
  }, [])
  const precio = extractIdAndPrice(lista)

  return { tabs, stars, lista, precio }
}

// Ordenar ciudades tabs
export const ordenarCiudades = <T>(ciudades: Array<T>, ordenCiudades: any) => {
  return ciudades?.sort((a: any, b: any) => {
    const ordenA: any = ordenCiudades.find((o: any) => o.destination_id === a.id)?.orden
    const ordenB: any = ordenCiudades.find((o: any) => o.destination_id === b.id)?.orden

    if (ordenA < ordenB) {
      return -1
    }
    if (ordenA > ordenB) {
      return 1
    }
    return 0
  })
}

// Ordenar hoteles search
export const ordenarSearch: any = [
  null, // Sin orden (Relevancia)
  (a: any, b: any) => { // Precio (menor a mayor)
    if(!a || !b) return 0
    return primerPrecio(a) - primerPrecio(b)
  },
  (a: any, b: any) => { // Precio (mayor a menor)
    const keys = Object.keys(b.pack_min_price)
    const lastKey = keys.sort()[keys.length - 1]
    return (b.pack_min_price[lastKey]?.price || 0) - (a.pack_min_price[lastKey]?.price || 0)
  },
  (a: any, b: any) => { // Estrellas (menor a mayor)
    return a.star?.order - b.star?.order
  },
  (a: any, b: any) => { // Estrellas (mayor a menor)
    return b.star?.order - a.star?.order
  }
]

// Obtener primer precio del primer pack disponible
export const primerPrecio = (hotel: any) => {
  if (!hotel) { return 0 }
  const packMinPrice = hotel?.pack_min_price;
  const firstKeyWithPrice: any = Object.keys(packMinPrice).find(key => packMinPrice[key].price || packMinPrice[key].price_label)
  const firstPriceOrLabel = packMinPrice[firstKeyWithPrice]

  return firstPriceOrLabel?.price
}

// Obtener pack hora del primer precio del primer pack disponible
export const primerPrecioPack = (hotel: any) => {
  if (!hotel) { return 0 }
  const packMinPrice = hotel?.pack_min_price;
  const firstKeyWithPrice: any = Object.keys(packMinPrice).find(key => packMinPrice[key].price || packMinPrice[key].price_label)
  return firstKeyWithPrice
}

// Obtener primer precio label del primer pack disponible
export const primerPrecioLabel = (hotel: any) => {
  if (!hotel) { return 0 }
  const packMinPrice = hotel?.pack_min_price;
  const firstKeyWithPrice: any = Object.keys(packMinPrice).find(key => packMinPrice[key].price || packMinPrice[key].price_label)
  const firstPriceOrLabel = packMinPrice[firstKeyWithPrice]

  return firstPriceOrLabel?.price_label
}

// Obtener último precio del último pack disponible
export const ultimoPrecio = (hotel: any) => {
  return hotel.pack_min_price[Object.keys(hotel.pack_min_price)[Object.keys(hotel.pack_min_price).length - 1]].price ?? 0
}

// Obtener array de horarios dado un hotel
export const getHorasHotel = (hotel: any, idioma: string) => {
  return Object.keys(hotel?.checkin_times || {}).reduce((acc: any, key: any) => {
    acc[key] = Object.keys(hotel?.checkin_times[key] || {}).filter(subKey => hotel?.checkin_times[key][subKey] === true).map(subKey => changeHourFormat(idioma) ? format12Hour(subKey) : subKey) 
    return acc
  }, {})
}

// Obtener la hora maxima para añadir horas extra
export const getMaxHour = (hotel: any) => {
  if(hotel?.checkin_times){
    const firstPack = Object.keys(hotel?.checkin_times)[0]
    const lastHour: string | undefined = Object.keys(hotel?.checkin_times[firstPack]).pop()

    // Convertir la cadena "HH:MM" a un objeto Date
    const parts: any = lastHour?.split(":")
    const date = new Date();
    date.setHours(parts[0], parts[1], 0, 0)

    // Sumar 3 horas
    date.setHours(date.getHours() + 3)
    
    // Formatear de vuelta a una cadena "HH:MM"
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')

    return `${hours}:${minutes}`
  }
}

// Diferencia entre horas
export const diffHours = (start: string, end: string): number => {
  // Convertir las cadenas a objetos Date
  const startDate: any = createDateFromString(start)
  const endDate: any = createDateFromString(end)

  // Calcular la diferencia en milisegundos
  const difference = endDate - startDate

  // Convertir la diferencia de milisegundos a horas
  const hoursDifference: number = difference / (1000 * 60 * 60)

  return hoursDifference
}

export const createDateFromString = (time: string) => {
  const parts: any = time.split(':')
  return new Date(0, 0, 0, parts[0], parts[1])
}

// Obtener query de url de un destination
export const getDestinationUrl = async (destination_id: number, locale: Ref) => {
  if(destination_id){
    const runtimeConfig = useRuntimeConfig()
    const { data: destinationData }: any = await useFetch(`${process.client ? runtimeConfig.public.API_CLIENT_BASE_URL : runtimeConfig.public.API_SERVER_BASE_URL}/destination-id`, {
      headers: { "Content-Type": "application/json" },
      query: { 
        destination_id,
        locale
      },
      onResponseError(res) {
        console.log(res)
      },
      onRequestError(err) {
        console.log(err)
      }
    })
    const [latitude, longitude] = destinationData.value?.data?.location?.split(',').reverse()
    const objQuery: any = {
      'data-destinationId': destinationData.value?.data?.element_id,
      'data-longitude': longitude,
      'data-latitude': latitude,
      'data-distance': destinationData.value?.data?.search_distance,
      'data-search': destinationData.value?.data?.name_translated,
      'data-country': destinationData.value?.data?.country_name_translated,
      'search': destinationData.value?.data?.name_translated,
    }

    return Object.keys(objQuery).map(key => `${key}=${objQuery[key]}`).join('&')
  }
  return ''
}